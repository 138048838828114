<template lang="pug">
  Section.cy-points-section(
    required
    contains-dropdown
    :class="{ 'invalid-section': object.$error }"
    :title="$t('plan_settings.basic_settings.sections.points.section_name')"
  )
    .row.no-gutters.w-100
      .col
        AppDropdown.cy-points(
          close-on-select
          order-direction="keep"
          :class="{ invalid: object.$error }"
          :items="items"
          :value="selectedPoints"
          @select="handleSelect"
        )
      .col
</template>

<script>
  import { find } from "lodash-es"
  import { getPointsOptions } from "@/config/translations_helper"

  export default {
    props: {
      object: Object
    },

    components: {
      Section: () => import("@/pages/PriceManagement/PlanSettings/BasicSettings/Section"),
      AppDropdown: () => import("@/components/elements/AppDropdown")
    },

    computed: {
      items() {
        return getPointsOptions(this.$i18n)
      },

      selectedPoints() {
        return find(this.items, { id: this.object.$model })
      }
    },

    methods: {
      handleSelect({ id }) {
        this.object.$model = id
      }
    }
  }
</script>
